import React from "react";
import { Box, Heading, Text, List, ListItem, UnorderedList } from "@chakra-ui/react";

export const LogimantaComponent: React.FC<{ props: any }> = ({ props }) => {
    return (
        <Box maxW="6xl" mx="auto" p="6" data-aos="fade-up" data-aos-duration="3000">
            <Heading as="h1" size="xl" mb={5} data-aos="fade-up" data-aos-duration="1000">
                {props.privacyPolicy.title}
            </Heading>
            <Text mb={5} data-aos="fade-up" data-aos-duration="1000">
                <strong>{props.privacyPolicy.date.ppoint}</strong>
                {props.privacyPolicy.date?.point}
            </Text>
            <Text mb={5} data-aos="fade-up" data-aos-duration="1000">
                {props.privacyPolicy.description}
            </Text>

            <Heading as="h2" size="lg" mb={4} data-aos="fade-up" data-aos-duration="1000">
                {props.privacyPolicy.information?.titile}
            </Heading>
            <Text mb={5} data-aos="fade-up" data-aos-duration="1000">
                {props.privacyPolicy.information?.value}
            </Text>

            {props.privacyPolicy.services && (
                <List spacing={2}>
                    {props.privacyPolicy.services.map((service: any, index: number) => (
                        <ListItem key={index}>
                            <Heading
                                as="h3"
                                size="md"
                                mb={5}
                                data-aos="fade-up"
                                data-aos-duration="1000"
                            >
                                {service.title}
                            </Heading>
                            <UnorderedList mt="2" pl="5">
                                {service.values?.map((value: any, i: number) => (
                                    <ListItem key={i} data-aos="fade-up" data-aos-duration="1000">
                                        <strong>{value.ppoint}</strong> {value.point}
                                    </ListItem>
                                ))}
                            </UnorderedList>
                        </ListItem>
                    ))}
                </List>
            )}
            {props.content.map((section: any, index: number) => (
                <Box key={index} mb={4} mt={8}>
                    <Heading as="h3" size="md" mb={5} data-aos="fade-up" data-aos-duration="1000">
                        {section.title}
                    </Heading>
                    {section.descriptions &&
                        section.descriptions.map((description: string, idx: number) => (
                            <Text mb={5} data-aos="fade-up" data-aos-duration="1000">
                                {description}
                            </Text>
                        ))}
                    {section.useKeyValue ? (
                        <UnorderedList mb={4} pl={4}>
                            {section.points &&
                                section.points.map((point: any, idx: number) => (
                                    <ListItem key={idx} data-aos="fade-up" data-aos-duration="1000">
                                        <strong>{point.ppoint}</strong> {point.point}
                                    </ListItem>
                                ))}
                        </UnorderedList>
                    ) : (
                        <UnorderedList mb={4} pl={4}>
                            {section.points &&
                                section.points.map((point: string, idx: number) => (
                                    <ListItem key={idx} data-aos="fade-up" data-aos-duration="1000">
                                        {point}
                                    </ListItem>
                                ))}
                        </UnorderedList>
                    )}
                </Box>
            ))}
        </Box>
    );
};
