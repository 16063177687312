import { ApplicationPolicyEnum } from "../util/enums/application-policy-enum";
import { Application } from "../util/interface/application";
import { Policy } from "../util/interface/policy";

export const AssetsService =
{
    getSocial() {
        return [
            {
                icon: "FaFacebook",
                enumType: "Facebook"
            },
            {
                icon: "FaInstagram",
                enumType: "Instagram"
            },
            {
                icon: "FaLinkedin",
                enumType: "Linkedin"
            }
        ]
    },

    getPolicies() {
        return [
            {
                url: "https://blog.hubspot.com/hs-fs/hubfs/unordered-list-html.png?width=595&height=400&name=unordered-list-html.png",
                title: "BEVERAGES CLUSTER  ",
                body: "This Privacy Policy outlines the types of personal information collected, used, and shared when you use the Beverage Cluster Application (\"the Application\").",
                path: "beverages-cluster"
            },
            {
                url: "https://blog.hubspot.com/hs-fs/hubfs/unordered-list-html.png?width=595&height=400&name=unordered-list-html.png",
                title: "LOGIMANTA",
                body: "This privacy policy explains how we collect, use, disclose, and protect your personal information in relation to our vehicle, motorcycle, and spare parts management",
                path: "logimanta"
            },
        ] as Policy[];
    },

    getPolicyApplication(code: string) {
        const companyData: Application = {
            [ApplicationPolicyEnum.BeveragesCluster]: {
                privacyPolicy: {
                    title: "PRIVACY POLICY FOR BEVERAGES CLUSTER",
                    lastUpdated: "2024-04-26",
                    content: [
                        {
                            title: "Information Collection and Use",
                            description:
                                "To provide and improve the Application, we collect several different types of information:",
                            points: [
                                "Account Information: To access the Application, users are required to create an account. During the account creation process, we collect information such as your email address, name, and a password.",
                                "Client Information: Users, specifically pre-sellers, have the ability to create and manage client profiles within the Application. This may include collecting personal information about clients such as names, contact details, and addresses.",
                                "Background Location Usage: To ensure operational integrity and regulatory compliance, our application collects user location information in the background to verify that operations are conducted at the client's location. Additionally, this information is used to monitor and ensure advisors' compliance with regulatory requirements. Access to background location is essential to ensure that our users are performing their job activities at designated locations and fulfilling their work responsibilities.",
                                "Order and Visit Data: When pre-sellers visit clients and take orders, we collect information related to these visits and orders, including the date and time of the visit, the client's details, and the specifics of the orders placed.",
                            ],
                        },
                        {
                            title: "Use of Information",
                            description:
                                "The information we collect is used in various ways, including:",
                            points: [
                                "To facilitate the creation and management of user accounts.",
                                "To enable pre-sellers to conduct visits, create, and manage client profiles.",
                                "To improve and personalize the user experience within the Application.",
                                "To communicate with users regarding their account or any services provided by the Application.",
                                "To detect, prevent, and address technical issues.",
                                "To enable more efficient management of client visits by providing both user and client location data.",
                                "To verify the location and activity of advisors or users, thus ensuring operational integrity and regulatory compliance.",
                            ],
                        },
                        {
                            title: "Sharing of Information",
                            description:
                                "We do not sell, trade, or rent users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.",
                        },
                        {
                            title: "Security",
                            description:
                                "The security of your Personal Information is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.",
                        },
                        {
                            title: "Changes to This Privacy Policy",
                            description:
                                "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
                        },
                        {
                            title: "Contact Us",
                            description:
                                "If you have any questions about this Privacy Policy, please contact us.",
                            additionalInfo:
                                "By using the Application, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, please do not use our Application. Your continued use of the Application following the posting of changes to this policy will be deemed your acceptance of those changes.",
                        },
                    ],
                },
                location: {
                    title: "Location",
                    content: [
                        {
                            title: "Information Collection",
                            description:
                                "By using our application, we collect background location information from mobile devices assigned to each sales advisor of the company. This information includes precise real-time location data, even when the application is running in the background. We do not collect or store location information from devices not associated with an authorized advisor of the company.",
                        },
                        {
                            title: "Use of Information",
                            description:
                                "The background location information is solely used for monitoring and supervising the activities of the company's sales advisors. This information is utilized to:",
                            points: [
                                "Facilitate real-time tracking of the location of sales advisors while they conduct their work activities.",
                                "Enable company supervisors to verify the routes taken by advisors and analyze their compliance with designated geofences.",
                                "Improve sales process efficiency and productivity by optimizing routes and task assignments.",
                                "Certain advisors deliver products and it is necessary for the advisor's security to know their current location.",
                                "The advisors carry out self-sales and it is necessary to maintain the security of the products as well as the advisors.",
                            ],
                        },
                        {
                            title: "Sharing of Information",
                            description:
                                "The background location information collected by our application is shared only with authorized supervisors and administrators of the company. This information is not sold, rented, or shared with third parties unaffiliated with the company, except when necessary to comply with the law or protect our legal rights.",
                        },
                        {
                            title: "Information Security",
                            description:
                                "We are committed to safeguarding the security and confidentiality of the location information collected by our application. We implement appropriate technical and organizational measures to protect this information against unauthorized access, disclosure, or modification.",
                        },
                        {
                            title: "User Consent",
                            description:
                                "By using our application, company sales advisors provide explicit consent for the collection and use of their background location information for the purposes described in this Privacy Policy.",
                        },
                    ],
                },
            }, // END 
            [ApplicationPolicyEnum.Logimanta]: {
                privacyPolicy:
                {
                    title: "Logimanta Data Privacy Policy",
                    date: {
                        ppoint: 'This privacy policy is effective as of ',
                        point: "2024-06-13",
                    },
                    description:
                        "At Logimanta, we value and respect your privacy. This privacy policy explains how we collect, use, disclose, and protect your personal information in relation to our vehicle, motorcycle, and spare parts management and quality control system.",

                    information: {
                        titile: 'Information We Collect',
                        value: "We collect various types of information in the course of operating our system:",
                    },
                    services: [
                        {
                            title: 'Vehicles',
                            values: [
                                {
                                    ppoint: 'Vehicle Information:  ',
                                    point: "For example, we collect the make, year, model, and Vehicle Identification Number (VIN), which is also visible in the application",
                                },
                                {
                                    ppoint: 'BL Data: ',
                                    point: "Vehicle information entered through BL documents.",
                                },
                                {
                                    ppoint: 'Vehicle Release:  ',
                                    point: "Information based on release documents.",
                                },
                                {
                                    ppoint: 'VIN:',
                                    point: "Verification and search for specific vehicles using their VIN.",
                                },
                                {
                                    ppoint: 'Vehicle PDI:',
                                    point: "Mechanical and quality inspections, creation of orders for mechanical and paintwork, records of identified issues, assignment of labor and materials, and visualization of the status of vehicles in these processes.",
                                },

                            ]

                        },
                        {
                            title: 'Transfers',
                            values: [
                                {
                                    ppoint: 'Nannies and Drivers Data:',
                                    point: "Registration of nannies and drivers.",
                                },
                                {
                                    ppoint: 'Transfer Orders: ',
                                    point: "Information on specific dates, assigned drivers, and nannies.",
                                },
                                {
                                    ppoint: 'Reception Location:  ',
                                    point: "Location recorded in the application for tracking the place of reception of vehicles, spare parts, and motorcycles. Similarly, if you allow the application to access location services through the permission system used by your mobile operating system.",
                                },
                            ]

                        },
                        {
                            title: 'Reception and Parking',
                            values: [
                                {
                                    ppoint: 'Yards:',
                                    point: "Creation and visualization of yards as parking areas.",
                                },

                            ]
                        },
                        {
                            title: 'Accessories',
                            values: [
                                {
                                    ppoint: 'Personnel Profiles:',
                                    point: "Data on personnel dedicated to accessory installation.",
                                },
                                {
                                    ppoint: 'Accessories:',
                                    point: "Information about created accessories and vehicle assignments.",
                                },

                            ]
                        },
                        {
                            title: 'Motorcycles',
                            values: [
                                {
                                    ppoint: 'Motorcycle Information:',
                                    point: "For example, we collect the make, year, color, model, and identification number (VIN or CHASSIS), which is also visible in the application.",
                                },
                                {
                                    ppoint: 'BL and Release Data:',
                                    point: "Information similar to that of vehicles but applied to motorcycles.",
                                },
                                {
                                    ppoint: 'Warehouses: ',
                                    point: "Creation and visualization of warehouses",
                                },
                                {
                                    ppoint: 'Motorcycle PDI: ',
                                    point: "Quality inspections, creation of orders for mechanical and paintwork, records of identified issues, assignment of labor and materials, and visualization of the status of motorcycles in these processes.",
                                },

                            ]
                        },
                        {
                            title: 'Fleets',
                            values: [
                                {
                                    ppoint: 'Fleets:',
                                    point: "Creation and visualization of transfers and deliveries.",
                                },
                                {
                                    ppoint: 'Work Request',
                                    point: "Registration of stages in the maintenance and repair process.",
                                },
                                {
                                    ppoint: 'Fleet Control: ',
                                    point: "Configuration and monitoring of maintenance elements",
                                },
                            ]
                        },
                        {
                            title: 'Spare Parts',
                            values: [
                                {
                                    ppoint: 'Packlist',
                                    point: "Information on spare parts entered through Excel files.",
                                },
                                {
                                    ppoint: 'Orders: ',
                                    point: "Management of spare parts orders by brand.",
                                },
                            ]
                        },
                        {
                            title: 'Spare Parts Dispatch',
                            values: [
                                {
                                    ppoint: 'Visualization of Plans',
                                    point: "Allows the visualization of plans made by the motorcycle process and the sending of spare parts packages",
                                },
                                {
                                    ppoint: 'Visualization of Dispatch Transfers: ',
                                    point: "Allows the visualization of dispatch transfers involving spare parts packages for delivery.",
                                },
                                {
                                    ppoint: 'Visualization of Deliveries: ',
                                    point: "Offers the ability to visualize deliveries made to both Logimanta trucks and courier companies.",
                                },
                                {
                                    ppoint: 'Creation of Delivery to an External Courier: ',
                                    point: "Provides the option to create deliveries intended for an external courier company.",
                                },
                            ]
                        },
                    ],


                },
                content: [
                    {
                        title: "Use of Information",
                        description:
                            "We use the collected information to:",
                        useKeyValue: false,
                        points: [
                            "Manage and optimize our logistics and quality control processes.",
                            "Verify and release vehicles and motorcycles.",
                            "Assign and manage transfer and dispatch orders.",
                            "Maintain detailed records of maintenance and repairs",
                            "Manage spare parts orders and shipments",
                            "Track and monitor the reception location of vehicles, spare parts, and motorcycles.",
                            "Visualize plans and dispatch transfers of spare parts, as well as completed deliveries.",
                        ],
                    },

                    {
                        title: "Information Disclosure",
                        descriptions: [
                            "We do not sell or rent your personal information to third parties. We may share your information with:",
                        ],
                        useKeyValue: true,
                        points: [
                            {
                                ppoint: 'Service Providers: ',
                                point: "Companies that provide services on our behalf.",
                            },
                            {
                                ppoint: 'Government Entities: ',
                                point: "When required by law or to protect our legal rights.",
                            },
                        ],
                    },

                    {
                        title: "Information Disclosure",
                        descriptions: [
                            "We implement security measures to protect your personal information against unauthorized access, alterations, disclosures, or destruction. However, no system is completely secure, so we cannot guarantee the absolute security of your information.",
                        ],
                        useKeyValue: false,
                    },
                    {
                        title: "Access and Correction of Information",
                        descriptions: [
                            "You have the right to access and correct your personal information. If you wish to review, update, or correct your information, please contact us.",
                        ],
                        useKeyValue: false,
                    },
                    {
                        title: "Changes to this Privacy Policy",
                        descriptions: [
                            "We may update this privacy policy periodically. We will notify you of any changes by posting the new policy in our system.",
                        ],
                        useKeyValue: false,
                    },
                    {
                        title: "Contact Us",
                        descriptions: [
                            "If you have any questions or concerns about this privacy policy, please contact us.",
                            "By using the Application, you signify your acceptance of this Privacy Policy. If you do not agree with this policy, please do not use our Application. Your continued use of the Application following the posting of changes to this policy will be deemed your acceptance of those changes.",
                        ],
                        useKeyValue: false,
                    },
                    {
                        title: "Consent",
                        descriptions: [
                            "By using our system, you consent to the collection and use of your personal information in accordance with this privacy policy.",
                        ],
                        useKeyValue: false,
                    },
                ]

            }
        };

        return companyData[code || ""] ?? null;
    }
}
