import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Heading, Text, UnorderedList, ListItem } from "@chakra-ui/react";
import { AssetsService } from "../../service/assets-service";
import { NotFoundComponent } from "../not-fount/NotFoundComponent";
import { DrinksComponent } from "./projects/DrinksComponent";
import { LogimantaComponent } from "./projects/LogimantaComponent";
import { ApplicationPolicyEnum } from "../../util/enums/application-policy-enum";

export const InformationPolicyComponent = () => {
    const { appCode } = useParams();

    const [dataInformation, setDataInformation] = useState<any>();

    useEffect(() => {
        setDataInformation(AssetsService.getPolicyApplication(appCode ?? ""));
    }, [appCode]);

    let content;
    switch (appCode) {
        case ApplicationPolicyEnum.BeveragesCluster:
            content = <DrinksComponent props={dataInformation} />;
            break;
        case ApplicationPolicyEnum.Logimanta:
            content = <LogimantaComponent props={dataInformation} />;
            break;
        default:
            content = <NotFoundComponent code={appCode} />;
            break;
    }

    return <div>{!dataInformation ? <NotFoundComponent code={appCode} /> : content}</div>;
};
